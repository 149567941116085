import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {ReactComponent as ArrowIcon} from "assets/common/arrow.svg";
import {ReactComponent as CheckIcon} from "assets/check.svg";
import './styles.scss'
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import React, {useContext, useEffect, useState} from "react";
import {ILanguage, languages} from "../../../constants/language.constants";
import {ThemeContext} from "../../../context/themeContext";
import {i18n} from "../../../i18n";
import {setLanguageToStorage} from "../../../utils/localStorage/language.utils";
import {defaultLanguage, getInitialLanguageFromStorage } from "components/FlagDropdown/FlagDropdown";
import {PROFILE_ROUTE} from "../../../constants/routes.constants";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";
export const Languages = () => {
    const navigate = useNavigate();
    const app = useTranslation('app');

    const [selectedLanguage, setSelectedLanguage] =
        useState<ILanguage>(getInitialLanguageFromStorage()||defaultLanguage);
    const { theme } = useContext(ThemeContext);

    const trackMixpanel = async() => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const clickid = localStorage.getItem("clickid")
        const email = localStorage.getItem("email")
        const data = {
            event: 'language_change',
            data:{
                property: selectedLanguage.value,
            },
            fbclid: fbclid,
            clickid: clickid,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }

    const onLanguageChange=()=>{
        i18n.changeLanguage(selectedLanguage.value)
        setLanguageToStorage(selectedLanguage.value)
        setSelectedLanguage(selectedLanguage)
        navigate(PROFILE_ROUTE)
        trackMixpanel()
    }

    useEffect(()=>{
        setSelectedLanguage(getInitialLanguageFromStorage()||defaultLanguage)

        const lngFromStorage=getInitialLanguageFromStorage()?.value;
        i18n.changeLanguage(lngFromStorage||defaultLanguage.value)

        return ()=>setLanguageToStorage(selectedLanguage.value)
    },[])
    return(
        <div className="languages-wrapper">
            <div className="tabs-header-container row-container space-between align-center">
                <ArrowIcon className="tabs-header-container__back" onClick={() => navigate(-1)} />
                <div className="tabs-header-container__title typography-14 typography-bold">
                    {app.t("language")}
                </div>
                <div/>
            </div>
            <div className="languages-wrapper__content">
                <div className="languages-wrapper__content__options column">
                    <div className="languages-wrapper__content__option row-container space-between" onClick={() => setSelectedLanguage(languages[0])}>
                        <div className="row-container" style={{gap: 8}}>
                            <img loading="lazy" alt={''} src={languages[0].flagImage} className='flagIcon'/>
                            <div className="typography-14 typography-400">{app.t("english")}</div>
                        </div>
                        {selectedLanguage === languages[0] && <CheckIcon/>}
                    </div>
                    <div className="languages-wrapper__content__option row-container space-between" onClick={() => setSelectedLanguage(languages[4])}>
                        <div className="row-container" style={{gap: 8}}>
                            <img loading="lazy" alt={''} src={languages[4].flagImage} className='flagIcon'/>
                            <div className="typography-14 typography-400">{app.t("french")}</div>
                        </div>
                        {selectedLanguage === languages[4] && <CheckIcon/>}
                    </div>
                    <div className="languages-wrapper__content__option row-container space-between" onClick={() => setSelectedLanguage(languages[2])}>
                        <div className="row-container" style={{gap: 8}}>
                            <img loading="lazy" alt={''} src={languages[2].flagImage} className='flagIcon'/>
                            <div className="typography-14 typography-400">{app.t("german")}</div>
                        </div>
                        {selectedLanguage === languages[2] && <CheckIcon/>}
                    </div>
                    <div className="languages-wrapper__content__option row-container space-between" onClick={() => setSelectedLanguage(languages[5])}>
                        <div className="row-container" style={{gap: 8}}>
                            <img loading="lazy" alt={''} src={languages[5].flagImage} className='flagIcon'/>
                            <div className="typography-14 typography-400">{app.t("polish")}</div>
                        </div>
                        {selectedLanguage === languages[5] && <CheckIcon/>}
                    </div>
                    <div className="languages-wrapper__content__option row-container space-between" onClick={() => setSelectedLanguage(languages[1])}>
                        <div className="row-container" style={{gap: 8}}>
                            <img loading="lazy" alt={''} src={languages[1].flagImage} className='flagIcon'/>
                            <div className="typography-14 typography-400">{app.t("portuguese")}</div>
                        </div>
                        {selectedLanguage === languages[1] && <CheckIcon/>}
                    </div>
                    <div className="languages-wrapper__content__option row-container space-between" onClick={() => setSelectedLanguage(languages[3])}>
                        <div className="row-container" style={{gap: 8}}>
                            <img loading="lazy" alt={''} src={languages[3].flagImage} className='flagIcon'/>
                            <div className="typography-14 typography-400">{app.t("spanish")}</div>
                        </div>
                        {selectedLanguage === languages[3] && <CheckIcon/>}
                    </div>
                </div>

                <Button className="btn-change-password" onClick={onLanguageChange} text={app.t("change_language")}
                        buttonTheme={ButtonThemes.DARK} width={100}
                />
            </div>
        </div>
    )
}