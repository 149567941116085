import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './CurrentRelationshipScreen.scss';
import {currentRelationship} from "../../../assets/onBoardingImages";
import {OnBoardingImage} from "../../../components/onboarding/OnBoardingImage/OnBoardingImage";
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {MIXPANEL_API} from "../../../components/onboarding/PayInfoContainer/constants";

const answers = [
    'You\'re on the verge of breaking up.',
    'Everything is perfect.',
    'I find it difficult to answer.'
]

export const CurrentRelationshipScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const currentRelationshipAnswerOptions:string[]= [
        t("currentRelationshipScreen.breakingUpStageButton"),
        t("currentRelationshipScreen.perfectStageButton"),
        t("currentRelationshipScreen.difficultQuestionButton")];


    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const clickid = localStorage.getItem("clickid")
        const email = localStorage.getItem("email")
        const data = {
            event: 'onboarding_question_responded',
            data:{
                onboarding_step_number: 2,
                question: 'How satisfied are you with your current relationship with your partner?',
                respond: answers[option],
            },
            fbclid: fbclid,
            clickid: clickid,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }

    return (
        <>
            <ContentContainer>
                <OnBoardingImage imageSrc={currentRelationship} />
            </ContentContainer>
            <p className={'currentRelationshipQuestion'}>{t("currentRelationshipScreen.stageCheckQuestion")}</p>
            <div>
                {currentRelationshipAnswerOptions.map((optionText, idx)=>{
                    return  <NextRouteButton key={optionText} option={optionText}
                                     route={nextRoute}
                                             onClick={() => trackMixpanel(idx)}
                                     buttonTheme={ButtonThemes.DARK}
                                     className={'currentRelationshipAnswerButton'} />
                })}
            </div>
        </>
    )
}
