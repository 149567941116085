import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Modal from 'react-modal';
import {ReactComponent as CloseIcon} from "assets/common/cross.svg";
import {useTranslation} from "react-i18next";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import Select from 'react-select';
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import {ButtonThemes} from "../../../constants/button.constants";
import {ThemeContext} from "../../../context/themeContext";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {FormErrorMessages} from "../../../constants/logInErrorMessages.constants";
import {TFunction} from "i18next";
import {
    PAYMENT_PREMIUM_API,
    URL_CITY,
    URL_COUNTRIES,
    URL_STATE
} from "../../../components/onboarding/PayInfoContainer/constants";
import { Input } from 'components/lego/Input/Input';
import CardInfo from 'components/onboarding/PayInfoContainer/CardInfo';
import {PayCardsContainer} from "../../../components/onboarding/PayInfoContainer/PayCardsContainer/PayCardsContainer";
import { Button } from 'components/lego/Button/Button';


export type TProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
    t: TFunction;
}

export type TSelectValue = {
    value: string;
    label: string
}

const FIRST_NAME_ID ='first_name';
const LAST_NAME_ID ='last_name';
const ZIP_ID ='zp';
const EMAIL_ID='email'
export const Payment = ({open, setOpen}: TProps) => {
    const { theme } = useContext(ThemeContext);

    const {t} = useTranslation('app')
    const onboarding= useTranslation('onboarding')
    const [isCardNumberForm, setIsCardNumberForm] = useState(false)
    const [first_name, setFirstName]=useState('');
    const [last_name, setLastName]=useState('');
    const [countryList, setCountryList]=useState([]);
    const [stateList, setStateList]=useState([]);
    const [cityList, setCityList]=useState([]);
    const [email,setEmail]=useState('')
    const [selectedCountry, setSelectedCountry] = useState<{value: string; label: string; tax: string} | null>(null)
    const [selectedState, setSelectedState] = useState<TSelectValue | null>(null)
    const [selectedCity, setSelectedCity] = useState<TSelectValue | null>(null)
    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCVV] = useState('');
    const [expDate, setExpDate] = useState('');
    const [cardName, setCardName] = useState('');
    const [zip, setZip]=useState('');
    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
    useEffect(() => {
        if(open){
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open])

    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }

    const getCountryList = useCallback(async () => {
        const res = await fetch(URL_COUNTRIES, {method: "GET"})
            .then((response) => response.json())
        if(!!res?.length) setCountryList(res?.map((i:{name: string, id: number, tax: number}) => ({label: i?.name, value: i?.id, tax: i.tax})))
    },[])

    const getStateList = useCallback(async () => {
        if(!selectedCountry) return
        const res = await fetch(URL_STATE(selectedCountry.value), {method: "GET"}).then((response) => response.json())
        if(!!res?.length)setStateList(res.map((i:{name: string, id: string}) => ({label: i.name, value: i.id})))
    },[selectedCountry])
    const getCityList = useCallback(async () => {
        if( !selectedState) return
        const res = await fetch(URL_CITY(selectedState?.value), {method: "GET"}).then((response) => response.json())
        setCityList(res.map((i:{name: string, id: string}) => ({label: i.name, value: i.id})))
    },[selectedState, selectedCountry])

    useEffect(() => {
        getCountryList()
    }, [])
    useEffect(() => {
        if(selectedCountry?.value)
            getStateList()
    }, [selectedCountry])
    useEffect(() => {
        if(selectedState?.value)
            getCityList()
    }, [selectedState, selectedCountry])

    const onSubmit  = async() => {
        if(!isCardNumberForm){
            return  setIsCardNumberForm(true)
        }
        if(!cardNumber || !cvv || !expDate || !cardName) {
            return
        }
        if(!isEmptyInput(first_name)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_USERNAME),errorInputId:FIRST_NAME_ID})
            return;
        }

        if(!isEmptyInput(last_name)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_USERNAME),errorInputId:LAST_NAME_ID})
            return;
        }
        if(!isEmptyInput(email)) {
            setErrorState({message:onboarding.t(FormErrorMessages.EMPTY_EMAIL),errorInputId:EMAIL_ID})
            return;
        }
        if(!selectedCountry?.value) {
            return;
        }
        if(!selectedState?.value) {
            return;
        }

        if(!isEmailValid(email)) {
            setErrorState({message:onboarding.t(FormErrorMessages.NOT_VALID_EMAIL),errorInputId:EMAIL_ID})
            return;
        }
        if(!isEmptyInput(zip)) {
            return;
        }
        const data = {
            "firstName": first_name,
            "lastName": last_name,
            "country": selectedCountry?.label,
            "state": selectedState?.label,
            "city": selectedCity?.label || 'n/a',
            "currency": "USD",
            "zipCode": zip,
            "address": selectedCountry?.label,
            "tax": selectedCountry?.tax,
            "customerBrowserUserAgent":window.navigator.userAgent,
            "customerIP":"",
            "card_name":cardNumber,
            "expiry_date":expDate,
            "secret":cvv,
            "card":cardNumber,
        }
        const token = localStorage.getItem("accessToken")
        const res = await fetch(PAYMENT_PREMIUM_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)}).then((response) => {
            return response.json()
        })
        if(!res.data?.payment_url) return
        window.open(res.data.payment_url, '_self')
    }

    const currentPrice = 24.99

    const wrongCard = useMemo(() => {
        if(cardNumber?.length <4) return false
        const firstDigit = cardNumber[0];
        const firstTwoDigits = cardNumber.slice(0, 2);
        const firstFourDigits = cardNumber.slice(0, 4);
        if (firstDigit === "4") return false ;
        if (firstTwoDigits >= "51" && firstTwoDigits <= "55") return false;
        if (firstFourDigits >= "2221" && firstFourDigits <= "2720") return false;
        return true

    }, [cardNumber])

    return(
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            contentLabel="Example Modal"
            preventScroll={true}
            className={"payment-modal" + ' ' + theme}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            style={{"overlay": {backgroundColor : theme === 'dark' ? '#171717' : '#ffffff'}}}
        >
            <div className="payment-modal-content">
                <div className="payment-modal-content__header-icon">
                    <CloseIcon onClick={() => {
                        setIsCardNumberForm(false)
                            setOpen(false)
                    }} />
                </div>
                <div className="payment-modal-content__header-title">
                    <div className="typography-28">
                        {t("payment_details")}
                    </div>
                </div>
                <div className={'paymentForm'}>
                    {!isCardNumberForm ?  <>
                        <Input onChange={(value) => clearErrorData(value, setFirstName)}
                               id={FIRST_NAME_ID}
                               inputType={'first_name'}
                               placeholderText={t("first_name")}
                               errorMessage={errorState.errorInputId === FIRST_NAME_ID ? errorState.message : ''}
                        />
                        <Input onChange={(value) => clearErrorData(value, setLastName)}
                               id={LAST_NAME_ID}
                               inputType={'last_name'}
                               placeholderText={t("last_name")}
                               errorMessage={errorState.errorInputId === LAST_NAME_ID ? errorState.message : ''}
                        />
                        <Input onChange={(value) => clearErrorData(value, setEmail)}
                               id={EMAIL_ID}
                               inputType={'email'}
                               placeholderText={onboarding.t("createAccountScreen.email.placeholder")}
                               errorMessage={errorState.errorInputId === EMAIL_ID ? errorState.message : ''}
                        />
                        <Select
                            onChange={setSelectedCountry}
                            options={countryList}
                            value={selectedCountry}
                            className={'basic-select'}
                            placeholder={"Country"}
                            classNamePrefix="select"
                            autoFocus={false}
                            backspaceRemovesValue={false}
                        />
                        <div className="two-in-the-row">
                            <Select
                                onChange={setSelectedState}
                                options={stateList}
                                placeholder={"State"}
                                value={selectedState}
                                className={'basic-select'}
                                classNamePrefix="select"
                                autoFocus={false}
                                backspaceRemovesValue={false}
                            />
                            <Select
                                onChange={setSelectedCity}
                                options={cityList}
                                value={selectedCity}
                                placeholder={"City"}
                                className={'basic-select'}
                                classNamePrefix="select"
                                autoFocus={false}
                                backspaceRemovesValue={false}
                            />
                            <Input onChange={(value) => clearErrorData(value, setZip)}
                                   id={ZIP_ID}
                                   inputType={'zip'}
                                   placeholderText={"Zip"}
                                   errorMessage={errorState.errorInputId === ZIP_ID ? errorState.message : ''}
                            />
                        </div>
                    </> : <CardInfo
                        cardNumber={cardNumber}
                        wrongCard={wrongCard}
                        expDate={expDate}
                        setExpDate={setExpDate}
                        setCardNumber={setCardNumber}
                        cvv={cvv}
                        setCVV={setCVV}
                        cardName={cardName}
                        setCardName={setCardName}
                    />}
                    <div className="payment-modal-content__footer-icon">
                        <PayCardsContainer/>
                    </div>
                    <div className="payment-modal-content__total">
                        <div className="payment-modal-content__total__name typography-32">{t("totalTitle")}</div>
                        <div className="column align-end">
                            <div
                                className="payment-modal-content__total__price typography-24 typography-bold">${(currentPrice + (!!selectedCountry?.tax ? +selectedCountry.tax * currentPrice : 0)).toFixed(2)}</div>
                            <div
                                className="payment-modal-content__total__save typography-14 mt-12">{currentPrice.toFixed(2)} +
                                ${((!!selectedCountry?.tax ? +selectedCountry?.tax : 0) * currentPrice).toFixed(2)} VAT
                            </div>
                        </div>
                    </div>


                    <Button className="mt-32" onClick={onSubmit} isDisabled={isCardNumberForm && wrongCard}
                            text={onboarding.t("testBeginningScreen.continueButton")}
                            buttonTheme={ButtonThemes.DARK} width={100}
                    />
                    <div className="payment-modal-content__footer-text mt-24">
                        {/*<div>{t("paymentFirstText")}</div>*/}
                        <div>{onboarding.t("payInfoScreen.footerText")}</div>
                        <div className="row-container justify-center text-align-center">G4Capital OÜ Harju maakond,
                        </div>
                        <div className="row-container justify-center text-align-center">Tallinn, Kesklinna linnaosa,
                            Narva mnt 13, 10151
                        </div>
                        <div>{`${onboarding.t("payInfoScreen.moneyBack.learnMoreText")} `}
                            &nbsp; <a
                                href={PolicyAnchors.SUBSCRIPTION}>{onboarding.t("payInfoScreen.subscriptionTermsAnchor")}</a>
                        </div>
                    </div>

                </div>
            </div>

        </Modal>
    )
}
